import tw from 'twin.macro';
import { Link } from 'gatsby';

export const FlexContainer = tw.div`w-full flex justify-center`;

export const VideoContainer = tw.div`overflow-hidden aspect-w-16 aspect-h-9 relative`;

export const SubHeader = tw.div`font-display font-normal tracking-wide hidden lg:block`;

export const DropPointsSection = tw.section`w-full py-8 md:py-16 px-8 mx-auto`;

export const DropPointsHeader = tw.h2`font-display w-full px-4 md:px-8 mb-8 md:mb-12 text-center text-2xl lg:text-4xl font-bold leading-tight text-gray-800`;

export const DropPointsWrapper = tw.div`w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-8`;

export const DropPointsContainer = tw.div`relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg lg:col-span-2`;

export const DropPointsImageContainer = tw.div`absolute pt-0 w-full h-full`;

export const City = tw.div`absolute pt-0 pl-0 w-full h-full flex items-center justify-center font-display font-bold leading-tight text-4xl text-white z-10`;

export const City2Container = tw.div`relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg lg:col-span-2`;

export const CommunityWrapper = tw.div`w-full md:flex flex-wrap items-center hidden`;

export const CommunityContainer = tw.div`flex justify-center items-center w-full md:w-1/2 h-64 md:h-full bg-center bg-contain bg-no-repeat`;

export const FindDropPointsContainer = tw.div`w-full md:w-1/2 px-6 md:px-8 lg:px-16 py-16 md:py-32 flex justify-start`;

export const CommunityHeader = tw.h3`font-display text-2xl md:text-3xl text-gray-800 font-bold leading-none mb-4`;

export const Paragraph = tw.p`text-gray-700 text-lg`;

export const DownloadSection = tw.section`w-full mx-auto text-center py-24 px-8 md:px-16 bg-primaryLight`;

export const DownloadHeader = tw.h2`font-display w-full my-2 text-2xl md:text-3xl lg:text-5xl font-bold leading-tight text-center`;

export const DownloadSubHeader = tw.h3`font-display text-xl lg:text-3xl leading-tight`;

export const BeDropPointSection = tw.div`w-full flex flex-wrap bg-white`;

export const BeDropPointContainer = tw.div`flex flex-col items-start`;

export const BeDropPointHeader = tw.h3`font-display text-3xl text-gray-800 font-bold leading-none mb-4
`;

export const BeDropPointLink = tw(
  Link
)`font-display flex font-bold leading-tight tracking-wide px-6 py-2 bg-primary-500 text-sm text-white rounded hover:bg-primary-600`;
