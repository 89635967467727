import React from 'react';

const BoxTime = ({ className }) => {
  return (
    <svg viewBox="0 0 512 512" className={className}>
      <title>Uhr Lieferzeit Paket</title>
      <path
        fill="currentColor"
        d="M407,273.463V135.55a7.031,7.031,0,0,0-3.539-6.083L211.5,18.638a7,7,0,0,0-7,0L12.628,129.415A7,7,0,0,0,9,135.55v240.9a7,7,0,0,0,3.5,6.062l192,110.85a7,7,0,0,0,7,0l103.122-59.53A103,103,0,1,0,407,273.463ZM319.126,96.942,141.141,199.7,106.21,179.53,284.191,76.772ZM134,211.624V339.933l-13.2-14.878a7.232,7.232,0,0,0-8.075-1.845L99,328.816V191.648ZM208,32.783l62.191,35.906L92.205,171.45,30.019,135.541Zm-7,442.393L23,372.409V147.654l62,35.917V339.28a7.135,7.135,0,0,0,9.821,6.47l18.654-7.691,22.058,25.046a7.013,7.013,0,0,0,5.262,2.375,7.563,7.563,0,0,0,2.574-.451A7.1,7.1,0,0,0,148,358.48V219.707l53,30.714ZM208,238.3l-52.859-30.518L333.126,105.025,385.98,135.54Zm7,236.879V250.421L393,147.653v125.81a103.015,103.015,0,0,0-85.413,148.264Zm185-9.956a89,89,0,1,1,89-89A89.32,89.32,0,0,1,400,465.22Z"
      />
      <path
        fill="currentColor"
        d="M266.865,250.635a7.01,7.01,0,0,0-11.267,1.49l-16.9,31.44a7,7,0,0,0,6.158,10.316,7.185,7.185,0,0,0,6.292-3.687L255,283.247V343.42a7,7,0,1,0,14,0V273.051l5.69,6.154a6.927,6.927,0,0,0,9.835.285,7,7,0,0,0,.255-9.9Z"
      />
      <path
        fill="currentColor"
        d="M41,338.45a7,7,0,0,0-7,7v25.92a7,7,0,0,0,14,0V345.45A7,7,0,0,0,41,338.45Z"
      />
      <path
        fill="currentColor"
        d="M69,357.65a7,7,0,0,0-7,7V388a7,7,0,0,0,14,0V364.65A7,7,0,0,0,69,357.65Z"
      />
      <path
        fill="currentColor"
        d="M98,376.85a7,7,0,0,0-7,7v20.56a7,7,0,0,0,14,0V383.85A7,7,0,0,0,98,376.85Z"
      />
      <path
        fill="currentColor"
        d="M126,396.05a7,7,0,0,0-7,7v17.77a7,7,0,0,0,14,0V403.05A7,7,0,0,0,126,396.05Z"
      />
      <path fill="currentColor" d="M148,437.61a7,7,0,0,0,14,0V422.25a7,7,0,0,0-14,0Z" />
      <path
        fill="currentColor"
        d="M276.16,353.467l-31.89,18.41a7,7,0,1,0,7,12.125l31.89-18.41a7,7,0,0,0-7-12.125Z"
      />
      <path
        fill="currentColor"
        d="M407,373.185V313.97a7,7,0,0,0-14,0v62.25a7,7,0,0,0,2.216,5.11l32.04,30a7,7,0,0,0,9.568-10.22Z"
      />
    </svg>
  );
};

export default BoxTime;
