import React from 'react';

const GrowthHand = ({ className }) => {
  return (
    <svg viewBox="0 -9 418 418" className={className}>
      <path
        fill="currentColor"
        d="m208.070312 400.226562c-14.59375 0-29.179687-2.941406-42.6875-8.816406l-64.589843-28.078125c-23.367188-10.164062-48.074219-16.601562-73.433594-19.136719l-21.210937-2.121093c-3.492188-.347657-6.148438-3.285157-6.148438-6.792969v-66.535156c0-1.476563.480469-2.914063 1.367188-4.09375 1.585937-2.121094 39.601562-51.785156 97.671874-38.863282 32.660157 7.257813 58.957032 20.839844 75.265626 30.957032 11.839843 7.34375 25.71875 11.222656 40.136718 11.222656h64.589844c11.132812 0 20.917969 5.890625 26.390625 14.726562l55.628906-64.089843c10.785157-14.402344 28.429688-23.222657 46.902344-23.222657h4.15625c2.308594 0 4.457031 1.164063 5.714844 3.09375 1.261719 1.929688 1.460937 4.371094.535156 6.480469l-50.3125 114.34375c-11 25.007813-33.398437 43.898438-59.910156 50.527344l-8.851563 2.210937-51.492187 20.597657c-12.671875 5.066406-26.199219 7.589843-39.722657 7.589843zm-194.414062-71.125 15.0625 1.507813c26.769531 2.675781 52.851562 9.476563 77.523438 20.203125l64.585937 28.078125c22.710937 9.875 48.902344 10.261719 71.898437 1.0625l51.914063-20.765625c.289063-.113281.582031-.207031.882813-.28125l9.300781-2.328125c22.449219-5.613281 41.414062-21.605469 50.722656-42.777344l45.890625-104.292969c-11.75 1.730469-22.5625 8.0625-29.765625 17.664063l-61.625 71.023437c.007813.265626.011719.527344.011719.796876 0 17.105468-13.917969 31.023437-31.023438 31.023437h-90.117187c-11.714844 0-23.292969 1.878906-34.40625 5.582031l-2.507813-6.367187-2.121094-6.488281.347657-.113282c12.472656-4.15625 25.5-6.269531 38.6875-6.269531h90.117187c9.574219 0 17.371094-7.792969 17.371094-17.371094s-7.792969-17.371093-17.371094-17.371093h-64.589844c-16.964843 0-33.332031-4.589844-47.332031-13.273438-15.40625-9.558594-40.242187-22.382812-71.027343-29.230469-43.976563-9.753906-75.621094 23.996094-82.425782 32.046875v57.941406zm0 0"
      />
      <path
        fill="currentColor"
        d="m268.867188 193.839844c-10.5 0-22.273438-3.242188-35.234376-9.722656-1.570312-.789063-2.78125-2.15625-3.375-3.808594-.527343-1.480469-12.726562-36.570313 6.660157-62.519532 14.089843-18.863281 40.582031-27.359374 78.785156-25.230468 3.484375.191406 6.261719 2.984375 6.433594 6.476562.136719 2.710938 2.953125 66.699219-29.675781 88.003906-6.9375 4.535157-14.820313 6.800782-23.59375 6.800782zm-26.527344-20.679688c17.933594 8.410156 32.269531 9.234375 42.660156 2.449219 19.347656-12.636719 23.53125-50.148437 23.632812-69.671875-29.933593-.730469-50.3125 6.015625-60.777343 20.019531-12.453125 16.675781-7.613281 39.613281-5.515625 47.203125zm0 0"
      />
      <path
        fill="currentColor"
        d="m164.460938 104.390625c-12.105469 0-26.070313-2.59375-38.257813-11.519531-20.671875-15.148438-30.171875-43.988282-28.242187-85.714844.164062-3.488281 2.929687-6.292969 6.417968-6.5 2.953125-.167969 72.851563-3.898438 96.371094 31.40625 10.953125 16.433594 10.054688 37.957031-2.660156 63.984375-.773438 1.582031-2.125 2.804687-3.777344 3.410156-.917969.339844-13.734375 4.933594-29.851562 4.933594zm27.496093-11.339844h.066407zm-80.558593-78.960937c-.589844 33.421875 7.09375 56.203125 22.871093 67.765625 18.890625 13.839843 44.683594 7.957031 52.792969 5.59375 9.277344-20.132813 10.0625-36.207031 2.328125-47.816407-14.375-21.578124-56.53125-25.796874-77.992187-25.542968zm0 0"
      />
      <path
        fill="currentColor"
        d="m217.777344 279.21875c-.214844 0-.429688-.011719-.648438-.03125-3.636718-.34375-6.359375-3.488281-6.175781-7.140625.402344-8.03125 1.089844-15.648437 2.023437-22.867187 2.839844-48.3125 1.375-160.820313-69.757812-191.945313-3.453125-1.511719-5.03125-5.535156-3.519531-8.992187 1.507812-3.449219 5.542969-5.023438 8.992187-3.515626 41.917969 18.339844 68.289063 64 76.246094 132.042969.714844 6.097657 1.25 12.109375 1.648438 17.964844 18.734374-47.339844 47.394531-64.066406 49.101562-65.023437 3.28125-1.855469 7.445312-.675782 9.296875 2.601562 1.839844 3.28125.683594 7.433594-2.585937 9.285156-.722657.421875-44.671876 26.796875-55.703126 108.132813-.839843 13.914062-2.050781 22.785156-2.164062 23.597656-.472656 3.40625-3.382812 5.890625-6.753906 5.890625zm0 0"
      />
    </svg>
  );
};

export default GrowthHand;
