import React from 'react';

const View = ({ className }) => {
  return (
    <svg viewBox="0 0 512.001 512.001" className={className}>
      <path
        fill="currentColor"
        d="M71.07,295.93c-1.859-1.861-4.439-2.93-7.069-2.93s-5.21,1.069-7.07,2.93c-1.86,1.861-2.93,4.44-2.93,7.07
        s1.069,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.07-2.931c1.86-1.859,2.93-4.439,2.93-7.069
        S72.931,297.79,71.07,295.93z"
      />

      <path
        fill="currentColor"
        d="M503.708,108.987l-62.976-89.604C432.201,7.246,418.251,0,403.417,0h-240.83c-14.835,0-28.784,7.246-37.315,19.384
        l-62.976,89.604c-5.426,7.72-8.295,16.788-8.295,26.225V267.5c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10V135.213
        c0-0.423,0.027-0.842,0.048-1.262h133.074V229c0,3.572,1.906,6.874,4.999,8.66c1.548,0.894,3.274,1.34,5.001,1.34
        c1.726,0,3.452-0.446,4.999-1.339l61.289-35.376l60.418,35.347c3.094,1.81,6.916,1.825,10.025,0.043
        c3.107-1.783,5.024-5.092,5.024-8.675v-95.049h133.074c0.021,0.42,0.048,0.839,0.048,1.262v277.178
        C492.001,426.512,480.513,438,466.392,438H355.403c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h110.989
        c25.15,0,45.609-20.46,45.609-45.609V135.213C512,125.776,509.133,116.708,503.708,108.987z M311.198,20l24.711,93.951H230.094
        L254.805,20H311.198z M209.414,113.951H83.251l58.382-83.067C146.424,24.068,154.256,20,162.586,20h71.539L209.414,113.951z
         M338.879,211.564l-50.384-29.477c-3.103-1.814-6.938-1.825-10.049-0.029l-51.323,29.623v-77.73h111.756V211.564z
         M356.589,113.951L331.878,20h71.539c8.33,0,16.162,4.068,20.952,10.884l58.382,83.067H356.589z"
      />

      <path
        fill="currentColor"
        d="M430.52,384.93c-1.859-1.86-4.439-2.93-7.069-2.93s-5.209,1.069-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
        s1.069,5.21,2.93,7.069c1.86,1.86,4.439,2.931,7.07,2.931c2.63,0,5.21-1.07,7.069-2.931c1.86-1.859,2.931-4.439,2.931-7.069
        S432.381,386.79,430.52,384.93z"
      />

      <path
        fill="currentColor"
        d="M390.847,382h-34.444c-5.522,0-10,4.478-10,10c0,5.522,4.477,10,10,10h34.444c5.522,0,10-4.478,10-10
        C400.847,386.478,396.369,382,390.847,382z"
      />

      <path
        fill="currentColor"
        d="M447.916,334h-91.471c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h91.471c5.522,0,10-4.478,10-10
        C457.916,338.478,453.438,334,447.916,334z"
      />

      <path
        fill="currentColor"
        d="M316.433,381.752c-7.295-8.563-19.184-21.532-34.404-34.69c-26.884-23.244-68.919-50.951-116.507-50.951
        c-47.653,0-89.653,27.577-116.494,50.711c-15.326,13.209-27.181,26.152-34.426,34.685C0.001,398.699,0.001,401.423,0.001,404.056
        c0,2.764,0,5.151,14.61,22.303c7.295,8.563,19.185,21.533,34.404,34.691c26.884,23.244,68.919,50.951,116.506,50.951
        c47.654,0,89.653-27.576,116.495-50.71c15.326-13.21,27.181-26.153,34.426-34.686c14.601-17.193,14.601-19.916,14.601-22.55
        C331.043,401.291,331.043,398.904,316.433,381.752z M266.018,448.642c-23.613,19.78-60.217,43.359-100.496,43.359
        c-40.316,0-77.041-23.816-100.75-43.796c-20.768-17.502-36.003-35.678-42.587-44.237c6.441-8.472,21.669-26.76,42.842-44.496
        c23.613-19.781,60.216-43.361,100.495-43.361c40.316,0,77.042,23.816,100.751,43.796c20.768,17.502,36.003,35.678,42.587,44.238
        C302.419,412.615,287.193,430.903,266.018,448.642z"
      />
      <path
        fill="currentColor"
        d="M165.522,336.479c-37.262,0-67.576,30.314-67.576,67.576c0,37.262,30.314,67.577,67.576,67.577
        c37.262,0,67.577-30.315,67.577-67.577C233.099,366.793,202.784,336.479,165.522,336.479z M165.522,451.633
        c-26.233,0-47.576-21.343-47.576-47.577c0-26.233,21.343-47.576,47.576-47.576c26.234,0,47.577,21.343,47.577,47.576
        C213.099,430.29,191.756,451.633,165.522,451.633z"
      />

      <path
        fill="currentColor"
        d="M165.522,373.043c-17.101,0-31.013,13.912-31.013,31.013c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10
        c0-6.072,4.94-11.013,11.013-11.013c5.522,0,10-4.478,10-10C175.522,377.521,171.044,373.043,165.522,373.043z"
      />
    </svg>
  );
};

export default View;
