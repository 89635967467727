import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import useMedia from 'use-media';

// import GooglePlay from '../images/googleplay.svg';
import HomeDelivery from '../svgs/HomeDelivery';
import Authentication from '../svgs/Authentication';
import Friendship from '../svgs/Friendship';
import BrowserCogs from '../svgs/BrowserCogs';
import BoxTime from '../svgs/BoxTime';
import View from '../svgs/View';
import FileCamera from '../svgs/FileCamera';
import Passport from '../svgs/Passport';
import GrowthHand from '../svgs/GrowthHand';

import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import Section from '../components/Section/';
import { Hero } from '../components/Hero/';
import { Testimonials } from '../components/Testimonials/';
import { ImageTextRow } from '../components/ImageTextRow/';
import Advantage, { AdvantageRow } from '../components/Advantage/';
import NewsletterForm from '../components/NewsletterForm';
import CompanyTestimonials from '../components/CompanyTestimonials/';
import { Badges } from '../components/Badges/';
import { AnchorLinks } from '../components/AnchorLinks/';
import '../index.css';
import { ArticleList } from '../components/ArticleList/';
import {
  FlexContainer,
  VideoContainer,
  SubHeader,
  DropPointsSection,
  DropPointsHeader,
  DropPointsWrapper,
  DropPointsContainer,
  DropPointsImageContainer,
  City,
  City2Container,
  CommunityWrapper,
  CommunityContainer,
  FindDropPointsContainer,
  CommunityHeader,
  Paragraph,
  DownloadSection,
  DownloadHeader,
  DownloadSubHeader,
  BeDropPointSection,
  BeDropPointContainer,
  BeDropPointHeader,
  BeDropPointLink,
} from '../styles/index';

const IndexPage = ({
  data: {
    HeroOma,
    Hero1,
    Hero2,
    Hero5,
    Hero6,
    Hero7,
    Postbote,
    SichereHande,
    Iphone1,
    City1,
    City2,
    Collage1,
    Collage2,
    Collage3,
    Collage4,
    Collage5,
    Collage6,
    Collage7,
    Selbstbestimmt,
  },
}) => {
  const isMobile = useMedia({ maxWidth: '1023px' });
  const images = [Hero1, Hero2, HeroOma, Hero5, Hero7, Hero6];

  return (
    <PageLayout>
      <SEO title="Nie wieder den Postboten verpassen" />
      <Hero
        // fullscreen
        images={images}
        title={
          <>
            Jeder wird zum Paketshop!
            <br />
            Per Smartphone App.
          </>
        }
        subTitle={isMobile ? '' : 'Nie wieder den Postboten verpassen!'}
        leftColumn={
          <FlexContainer>
            <div className="w-3/4 flex justify-center">
              <div className="lg:max-w-screen-md flex-1">
                <VideoContainer>
                  <iframe
                    // width="2032"
                    // height="400"
                    src="https://www.youtube.com/embed/TP27oKSy3H8"
                    title="Video über DropFriends"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </VideoContainer>
              </div>
            </div>
          </FlexContainer>
        }>
        <SubHeader>DropFriends-App downloaden & Haushaltskasse aufbessern.</SubHeader>
        <Badges />
      </Hero>
      <CompanyTestimonials />
      <AnchorLinks
        links={[
          { title: 'Allgemein', anchor: '/#allgemein' },
          { title: 'Blog', anchor: '/#blog' },
          { title: 'Vorteile', anchor: '/#vorteile' },
        ]}
      />

      <DropPointsSection>
        <div className="container mx-auto">
          <DropPointsHeader>Bereits mehr DropPoints als Paketschränke in:</DropPointsHeader>
          <DropPointsWrapper>
            <div className="hidden lg:block" />
            <DropPointsContainer>
              <DropPointsImageContainer>
                <GatsbyImage
                  image={City1.childImageSharp.gatsbyImageData}
                  className="w-full h-full"
                  objectFit="cover"
                  objectPosition="center center"
                  alt=""
                />
              </DropPointsImageContainer>
              <DropPointsImageContainer className="bg-black z-5" style={{ opacity: 0.33 }} />

              <City>Köln</City>
            </DropPointsContainer>
            <City2Container>
              <DropPointsImageContainer>
                <GatsbyImage
                  image={City2.childImageSharp.gatsbyImageData}
                  className="w-full h-full"
                  objectFit="cover"
                  objectPosition="center center"
                  alt=""
                />
              </DropPointsImageContainer>
              <DropPointsImageContainer className="bg-black z-5" style={{ opacity: 0.33 }} />
              <City>Hamburg</City>
            </City2Container>
          </DropPointsWrapper>
          <div className="hidden lg:block" />
        </div>
      </DropPointsSection>

      <Section title="Entdecke die DropFriends-Community" fullWidth titleId="allgemein">
        <CommunityWrapper>
          <CommunityContainer>
            <div className="w-full md:w-1/2">
              <GatsbyImage
                image={Iphone1.childImageSharp.gatsbyImageData}
                className="w-full h-auto"
                objectFit="contain"
                objectPosition="center center"
                alt=""
              />
            </div>
          </CommunityContainer>
          <FindDropPointsContainer>
            <div className="align-middle" style={{ maxWidth: 576 }}>
              <CommunityHeader className="md:mt-0 ">Finde DropPoints!</CommunityHeader>
              <Paragraph>
                Lade die DropFriends-App herunter und entdecke verfügbare DropPoints zur Annahme{' '}
                <span className="line-through">von Paketen</span> der Dinge, die Du so sehr liebst.
              </Paragraph>
            </div>
          </FindDropPointsContainer>
        </CommunityWrapper>

        <ImageTextRow rightImageFluid={Postbote} position="right" fullWidth>
          <CommunityHeader>Nie wieder den Paketboten verpassen!</CommunityHeader>
          <Paragraph>
            Dein DropPoint ist genau dann für den Lieferdienst erreichbar, wenn Du es nicht sein
            kannst.
          </Paragraph>
        </ImageTextRow>

        <ImageTextRow
          leftImageFluid={Collage3}
          rightImageMobileLast
          rightImageFluid={Collage1}
          fullWidth
        />

        <Testimonials
          title="Das sagt die Community"
          className="bg-white"
          testimonials={[
            {
              testimonial: (
                <>
                  Funktioniert!
                  <br />
                  In Köln gibts enorm viele Annahmestellen und ich habe meinen gefunden. Probiert‘s
                  aus!
                </>
              ),
              name: 'Katrin',
              source: 'Play Store',
            },
            {
              testimonial:
                'Ich kann die App wärmstens empfehlen. Bisher hat alles super geklappt und es kommen dauernd neue Orte hinzu, wo ich was hin liefern kann. Nach dem ganzen Stress, den ich bisher in meinem Leben mit Packstationen hatte, bin ich echt erleichtert, dass sich jemand um eine bessere Lösung bemüht hat.',
              name: 'Dennis ',
              source: 'Play Store',
            },
            {
              testimonial: (
                <>
                  Super!
                  <br />
                  Dank der App fühle ich mich bzw. meine Pakete gut aufgehoben. Das hier braucht
                  jeder und mein Gott, wie einfach alles war. Einfach genial!!!
                </>
              ),
              name: 'Engin ',
              source: 'Play Store',
            },
            {
              testimonial: (
                <>
                  Super App!
                  <br />
                  Läuft stabil und tut was sie soll.
                </>
              ),
              name: 'Chris ',
              source: 'Play Store',
            },
            {
              testimonial:
                'Finde, das ist eine sehr clevere App, viel Potenzial ist da! Ich habe schon einen DropPoint aufgemacht. Finde die Idee super!',

              name: 'Phillip ',
              source: 'Play Store',
            },
            {
              testimonial: (
                <>
                  Super App!
                  <br />
                  Unbedingt ausprobieren.
                </>
              ),
              name: 'Oleg ',
              source: 'Play Store',
            },
          ]}
        />

        <section className="w-full mx-auto text-center py-16 md:py-24 px-8 md:px-16 bg-primaryLight">
          <h2 className="font-display w-full my-2 text-2xl md:text-3xl lg:text-5xl font-bold leading-tight text-center">
            Jetzt die DropFriends-App downloaden.
          </h2>

          <h3 className="font-display mb-4 text-xl lg:text-3xl leading-tight">
            Erhältlich im App Store und im Google Play Store.
          </h3>

          <Badges className="my-4" />

          <NewsletterForm />
        </section>

        <ArticleList
          titleId="blog"
          articles={[
            {
              title: 'Warum Packstationen keine 100%ige Lösung sind',
              link: 'https://blog.dropfriends.com/2020/10/12/warum-packstationen-keine-losung-sind/',
              image:
                'https://blog.dropfriends.com/wp-content/uploads/2020/06/twitter-jankolario-Q-fFZtc1hS4-unsplash-copy-2048x1526.jpg',
              description:
                'DHL Packstationen, Amazon Lockers und Paketstationen der Deutschen Bahn möchten die Erstzustellung für Sendungen erleichtern. Doch ein Blick in die…',
            },
            {
              title: 'Warum DropPoints so sicher sind',
              link: 'https://blog.dropfriends.com/2020/06/09/warum-droppoints-so-sicher-sind/',
              image: 'https://blog.dropfriends.com/wp-content/uploads/2020/08/48308_small.jpg',
              description:
                'In diesem Blog-Posting möchte ich erklären, warum die Ablage von Deinen Paketen bei einem DropPoint so sicher ist und warum es Dir nicht egal sein...',
            },
            {
              title: 'Pakete annehmen, hilft der Umwelt',
              link: 'https://blog.dropfriends.com/2020/06/18/pakete-annehmen-hilft-der-umwelt/',
              image: 'https://blog.dropfriends.com/wp-content/uploads/2020/10/Stadt_02_small.jpg',
              description:
                'Seit Fridays for Future ist das Thema „Nachhaltigkeit“ und „das Reduzieren von CO2 Ausstoß“ in der Mitte der Bevölkerung, bei Konzernen und Kleinstunternehmen...',
            },
            {
              title: 'Selfies für Pakete',
              link: 'https://blog.dropfriends.com/2020/06/25/selfies-fuer-pakete/',
              image:
                'https://blog.dropfriends.com/wp-content/uploads/2020/06/portrait-of-a-cheerful-young-man-taking-a-selfie_small.jpg',
              description:
                'Dokumentationen zur Zustellung gehören dazu. Was über Jahrzehnte mit einer Unterschrift vom Boten durchgeführt wurde, reicht in der heutigen Zeit aber...',
            },
          ]}
        />

        <section className="md:hidden w-full mx-auto text-center py-16 md:py-24 px-8 md:px-16 bg-primaryLight">
          <h2 className="font-display w-full my-2 text-2xl md:text-3xl lg:text-5xl font-bold leading-tight text-center">
            Jetzt die DropFriends-App downloaden.
          </h2>

          <h3 className="font-display mb-4 text-xl lg:text-3xl leading-tight">
            Erhältlich im App Store und im Google Play Store.
          </h3>

          <Badges className="my-4" />

          <NewsletterForm />
        </section>

        <ImageTextRow leftImageFluid={SichereHande} fullWidth>
          <h3 className="font-display text-2xl md:text-3xl text-gray-800 font-bold leading-none mb-4">
            Deine Sendung in sicheren Händen!
          </h3>
          <Paragraph>
            Deine Sendung wird persönlich angenommen und für Dich per Foto festgehalten. Und weil
            Dein DropPoint weiß, dass Dir Dein Paket sehr wichtig ist, werden die Bilder an Dich
            über die App übermittelt - in Echtzeit.
          </Paragraph>
        </ImageTextRow>

        <ImageTextRow
          leftImageFluid={Collage6}
          rightImageMobileLast
          rightImageFluid={Collage7}
          fullWidth
        />

        <ImageTextRow rightImageFluid={Collage2} position="right" fullWidth>
          <h3 className="font-display text-2xl md:text-3xl text-gray-800 font-bold leading-none mb-4">
            Dein Paket - rund um die Uhr.
          </h3>
          <Paragraph>
            Dein DropPoint öffnet Dir die Tür und übergibt Dir Dein Paket. So kannst Du tagesgleich
            nach Erstzustellung Deine Lieblings-Dinge in Händen halten.
          </Paragraph>
        </ImageTextRow>
        <ImageTextRow leftImageFluid={Collage5} rightImageFluid={Collage4} fullWidth />
      </Section>

      <AdvantageRow title="Die DropFriends-Vorteile" titleId="vorteile">
        <Advantage
          icon={<HomeDelivery className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Nie wieder den Postboten verpassen"
          title="Sendungen sind stets zustellbar">
          <p>
            DropFriends verhindert umständliche Zustellungszeiträume von Lieferdiensten in Deinem
            Alltag. Egal ob Du an Deiner Arbeitsstelle oder bei wichtigen Terminen bist: Deine
            Sendungen sind durch DropPoints stets zustellbar - lieferdienstunabhängig. So werden
            unleserliche oder fehlende Benachrichtigungskarten vorgebeugt und Du kannst Deine Pakete
            tagesgleich auspacken.
          </p>
        </Advantage>

        <Advantage
          icon={<BoxTime className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Abholzeiten, wie für Dich gemacht"
          title="Rund um die Uhr">
          <p>
            Dank des Internets können wir Bestellungen rund um die Uhr aufgeben. Doch die Zustellung
            der Sendungen ist an Zeiträume gebunden, die den heutigen Ansprüchen mit Berufsalltag
            und Terminen nicht mehr entsprechen. Mit der DropFriends-App kannst Du vor Buchung
            einsehen, welcher DropPoint passende Abholzeiten anbietet, die sich uneingeschränkt in
            Deine Alltagspläne eingliedern.
          </p>
        </Advantage>

        <Advantage
          icon={<FileCamera className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Echtzeit-Dokumentation per App"
          title="Vertrauen und Qualität">
          <p>
            Du erhältst automatische Benachrichtigungen über die Ankunft Deiner Sendungen. Auch der
            Zustand der Pakete wird per Foto für Dich dokumentiert und in Echtzeit über die App zur
            Verfügung gestellt. So ist es Dir möglich den Annahmeprozess zu verfolgen und Du kannst
            sicher sein, dass alles mit rechten Dingen zugeht - so als ob Du das Paket selbst
            entgegengenommen hättest.
          </p>
        </Advantage>

        <Advantage
          icon={<View className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Zustellung nach Deinen Kriterien"
          title="Dein Eigentum, Deine Entscheidung">
          <p>
            Du kennst das: Du bist zur Paketübergabe z.B. im Büro und was passiert dann? Der
            Zusteller entscheidet das weitere Vorgehen mit Deinem Eigentum, beispielsweise die
            Abholung beim ungeliebten Nachbarn, die Übergabe in Shops mit ungünstigen Öffnungszeiten
            oder auch das unsichere Abstellen im Hausflur. Mit DropFriends entscheidest Du und
            kannst nach Erhalt des Paketes eine Bewertung hinterlassen.
          </p>
        </Advantage>

        <Advantage
          icon={<BrowserCogs className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Keine Wartungsarbeiten & Umleitungen"
          title="Dort, wo Du es möchtest">
          <p>
            DropPoints sind Gewerbeflächen und echte Haushalte. Es gibt also keine Wartungsarbeiten
            oder Kapazitätsmängel, die eine Zustellung von Paketen verhindern oder Umleitungen zu
            unbekannten Orten provozieren. Die DropPoints, die Dir in der App angezeigt werden,
            verfügen über ausreichend Kapazität für Deine Sendung. Der Platz für Dein Paket ist also
            sicher.
          </p>
        </Advantage>

        <Advantage
          icon={<Friendship className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Selfies und Namen gegen Anonymität"
          title="Man kennt sich. Man hilft sich.">
          <p>
            DropFriends verbindet Menschen gezielt dort, wo das Leben stattfindet. Als Kölner
            Unternehmen genießen wir das rheinische Lebensgefühl und möchten das unterstützende
            Miteinander auch in der App zum Ausdruck bringen. Entsprechend lächeln Dir Deine
            DropPoints auch neben Ihrem Namen auf Deinem Smartphone entgegen. Ganz nach dem Motto:
            Man kennt sich. Man hilft sich.
          </p>
        </Advantage>

        <Advantage
          icon={<Authentication className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Identifizierung an der Haustür"
          title="Sicher ist sicher">
          <p>
            Natürlich möchtest Du sichergehen, dass Deine Sendung auch ausschließlich nur von Dir
            beim DropPoint abgeholt werden kann. Bereits bei der Buchung wird Dir in den Kriterien
            Deines DropPoints dargestellt, welche Identifizierungsform an der Haustür durchgeführt
            wird. Stell sicher, ob Dir die Form der Prüfung zusagt und lass die Durchführung in
            Deine Bewertung mit einfließen.
          </p>
        </Advantage>

        <Advantage
          icon={<Passport className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline="Pakete auf Reisen empfangen"
          title="Shopping über Stadtgrenzen hinaus">
          <p>
            Internet-Einkäufe sind der bequeme Weg, um die Dinge zu erhalten, die Du so sehr liebst.
            Auf Reisen sind Bestellungen zwar möglich, doch Hotels verweigern die Annahme von
            Sendungen. Mit DropFriends kannst Du Deine Pakete zu DropPoints verschicken lassen, die
            im direkten Umfeld Deiner Unterkunft liegen. So wird Dein Online-Shopping-Erlebnis auch
            unterwegs zu 100% erfolgreich.
          </p>
        </Advantage>

        <Advantage
          icon={<GrowthHand className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          catchline={
            <>
              CO<sub>2</sub>-Ausstoß pro Paket verringern
            </>
          }
          title="Unserer Umwelt zuliebe">
          <p>
            Wusstest Du, dass das Durchschnittspaket 1 kg CO<sub>2</sub> ausstößt? Mit DropFriends
            werden mehrere Zustellversuche durch Lieferdienste und deren Benachrichtigungskarten
            reduziert. Die Wege zu Paketshops und -stationen werden kürzer. Auto und Bahn werden zur
            Abholung somit hinfällig und der CO<sub>2</sub>-Ausstoß pro Paket wird auf bis zu 277 g
            reduziert.
          </p>
        </Advantage>
      </AdvantageRow>

      <DownloadSection>
        <DownloadHeader>Jetzt die DropFriends-App downloaden.</DownloadHeader>

        <DownloadSubHeader>Erhältlich im App Store und im Google Play Store.</DownloadSubHeader>

        <Badges className="mt-4" />
      </DownloadSection>

      <BeDropPointSection>
        <ImageTextRow rightImageFluid={Selbstbestimmt} position="right" fullWidth>
          <BeDropPointContainer style={{ maxWidth: 576 }}>
            <BeDropPointHeader>Jetzt DropPoint werden</BeDropPointHeader>
            <Paragraph className=" mb-8">
              Verdiene Geld, während Du daheim bleibst und Deine Lieblingsserien schaust, Deine
              Kinder beaufsichtigst oder für die nächste Prüfung lernst.
              <br />
              <br />
              Lade die DropFriends-App herunter und nimm Pakete für Deine Community an, die zum
              Zustellungszeitpunkt z.B. ans Büro oder Hotel gebunden ist.
            </Paragraph>
            <BeDropPointLink to="/droppoint-werden">Hier informieren</BeDropPointLink>
          </BeDropPointContainer>
        </ImageTextRow>
      </BeDropPointSection>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Hero1: file(relativePath: { eq: "hero/1.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    HeroOma: file(relativePath: { eq: "collage/oma-mit-herz.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero2: file(relativePath: { eq: "hero/2.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero3: file(relativePath: { eq: "hero/3.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero5: file(relativePath: { eq: "hero/5.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero6: file(relativePath: { eq: "hero/6.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero7: file(relativePath: { eq: "hero/7.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Iphone1: file(relativePath: { eq: "dropfriends-screenshots.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    City1: file(relativePath: { eq: "koeln.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    City2: file(relativePath: { eq: "hamburg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Collage1: file(relativePath: { eq: "collage/vr.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Collage2: file(relativePath: { eq: "collage/skateboard.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Collage3: file(relativePath: { eq: "collage/glasses.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Collage4: file(relativePath: { eq: "collage/bag.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Collage5: file(relativePath: { eq: "collage/gaming.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Collage6: file(relativePath: { eq: "collage/shoes.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Collage7: file(relativePath: { eq: "collage/sports.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    SichereHande: file(relativePath: { eq: "sichere-hande.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Selbstbestimmt: file(relativePath: { eq: "annahmestelle/selbstbestimmt.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default IndexPage;
