import React from 'react';
import { Grid, Image, Section } from './styles';

import T3n from '../../images/testimonial-logos/t3n.png';
import InsideDigital from '../../images/testimonial-logos/inside-digital.png';
import PCWelt from '../../images/testimonial-logos/pc-welt.png';
import StartupValley from '../../images/testimonial-logos/startup-valley.png';
import Paketda from '../../images/testimonial-logos/paketda.png';
import RheinlandPitch from '../../images/testimonial-logos/rheinlandpitch.png';
import Startport from '../../images/testimonial-logos/startport.png';
import useMedia from 'use-media';

const CompanyTestimonials = ({ className }) => {
  const isMobile = useMedia({ maxWidth: '1023px' });

  return (
    <Section className={className}>
      <Grid style={{ gridTemplateColumns: isMobile ? 'repeat(8, 20%)' : '' }}>
        <Image src={T3n} alt="t3n" />
        <Image src={InsideDigital} alt="InsideDigital" />
        <Image src={PCWelt} alt="PCWelt" />
        <Image src={StartupValley} alt="StartupValley" />
        <Image src={Paketda} alt="Paketda" />
        <Image src={RheinlandPitch} alt="RheinlandPitch" />
        <Image src={Startport} className="w-full m-0" alt="Startport" />
      </Grid>
    </Section>
  );
};

export default CompanyTestimonials;
